import { render, staticRenderFns } from "./hasoneToone.vue?vue&type=template&id=72add71c&scoped=true&"
import script from "./hasoneToone.vue?vue&type=script&lang=js&"
export * from "./hasoneToone.vue?vue&type=script&lang=js&"
import style0 from "./hasoneToone.vue?vue&type=style&index=0&id=72add71c&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72add71c",
  null
  
)

export default component.exports